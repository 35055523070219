<template>
  <!--MODULE SPECIFIC FIELDS-->
  <div class="mt-1">
    <!--EMAIL VISIBLE-->
    <b-field>
      <b-switch v-model="$data[moduleFields.EMAIL_VISIBLE]">{{ $t("admin_panel.contact_info_email_visible") }} </b-switch>
    </b-field>

    <!--CUSTOM EMAIL-->
    <div class="field">
      <label class="label has-text-primary">{{ $t("admin_panel.contact_info_custom_email") }}</label>
      <b-button type="is-primary" class="admin-translation-key-button" @click="editCustomEmail">
        {{ customEmailLabel }}
      </b-button>
    </div>

    <!--PHONE VISIBLE-->
    <b-field>
      <b-switch v-model="$data[moduleFields.PHONE_VISIBLE]">{{ $t("admin_panel.contact_info_phone_visible") }} </b-switch>
    </b-field>

    <!--CUSTOM PHONE-->
    <div class="field">
      <label class="label has-text-primary">{{ $t("admin_panel.contact_info_custom_phone") }}</label>
      <b-button type="is-primary" class="admin-translation-key-button" @click="editCustomPhone">
        {{ customPhoneLabel }}
      </b-button>
    </div>

    <!--WEBSITE VISIBLE-->
    <b-field>
      <b-switch v-model="$data[moduleFields.WEBSITE_VISIBLE]">{{ $t("admin_panel.contact_info_website_visible") }} </b-switch>
    </b-field>

    <!--CUSTOM WEBSITE-->
    <div class="field">
      <label class="label has-text-primary">{{ $t("admin_panel.contact_info_custom_website") }}</label>
      <b-button type="is-primary" class="admin-translation-key-button" @click="editCustomWebsite">
        {{ customWebsiteLabel }}
      </b-button>
    </div>
  </div>
</template>

<script>
import LpConfigConstants from "@/shared/constants/lp-config-constants";

export default {
  name: "AdminModuleConfigContactInformation",
  props: {
    value: {
      type: Object,
      required: true,
    },
  },

  created() {
    this.$root.$on("translation-key-selected", this.onTranslationKeySelected);
  },

  beforeDestroy() {
    this.$root.$off("translation-key-selected", this.onTranslationKeySelected);
  },

  data() {
    const moduleFields = LpConfigConstants.EVENT_CONTACT_INFO_FIELDS;
    return {
      [moduleFields.EMAIL_VISIBLE]: !!this.value[moduleFields.EMAIL_VISIBLE],
      [moduleFields.PHONE_VISIBLE]: !!this.value[moduleFields.PHONE_VISIBLE],
      [moduleFields.WEBSITE_VISIBLE]: !!this.value[moduleFields.WEBSITE_VISIBLE],
      [moduleFields.CUSTOM_EMAIL]: this.value[moduleFields.CUSTOM_EMAIL],
      [moduleFields.CUSTOM_PHONE]: this.value[moduleFields.CUSTOM_PHONE],
      [moduleFields.CUSTOM_WWW]: this.value[moduleFields.CUSTOM_WWW],
      awaitingFieldForTranslationKey: null,
    };
  },

  computed: {
    moduleFields: () => LpConfigConstants.EVENT_CONTACT_INFO_FIELDS,

    specificFieldsObject() {
      return Object.values(this.moduleFields).reduce((acc, fieldKey) => {
        return { ...acc, [fieldKey]: this.$data[fieldKey] };
      }, {});
    },

    customEmailLabel() {
      return this.$data[this.moduleFields.CUSTOM_EMAIL] || this.$t("admin_panel.select_translation_key");
    },

    customWebsiteLabel() {
      return this.$data[this.moduleFields.CUSTOM_WWW] || this.$t("admin_panel.select_translation_key");
    },

    customPhoneLabel() {
      return this.$data[this.moduleFields.CUSTOM_PHONE] || this.$t("admin_panel.select_translation_key");
    },
  },

  methods: {
    editCustomEmail() {
      this.awaitingFieldForTranslationKey = this.moduleFields.CUSTOM_EMAIL;
      this.$root.$emit("select-translation-key");
    },

    editCustomPhone() {
      this.awaitingFieldForTranslationKey = this.moduleFields.CUSTOM_PHONE;
      this.$root.$emit("select-translation-key");
    },

    editCustomWebsite() {
      this.awaitingFieldForTranslationKey = this.moduleFields.CUSTOM_WWW;
      this.$root.$emit("select-translation-key");
    },

    onTranslationKeySelected(translationKey) {
      if (this.awaitingFieldForTranslationKey) {
        this.$data[this.awaitingFieldForTranslationKey] = translationKey;
      }
      this.awaitingFieldForTranslationKey = null;
    },
  },

  watch: {
    specificFieldsObject: {
      immediate: false,
      handler(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
};
</script>

<style scoped></style>
