<template>
  <div class="box">
    <b-button type="is-primary" class="has-fullwidth mb-1" @click="addTab" v-if="tabsEnabled || tabs.length === 0">
      {{ $t("admin_panel.add_tab") }}
    </b-button>

    <template v-for="(tab, index) in tabs">
      <div class="field box has-image is-relative" v-if="index === 0 || tabsEnabled" :key="`tab-${index}`">
        <!--LW LICENSE KEY-->
        <div class="field">
          <label class="label has-text-primary"> {{ $t("admin_panel.lw_license_key") }} </label>
          <div class="control">
            <input
              class="input is-simple"
              type="text"
              :placeholder="$t('admin_panel.lw_license_key')"
              v-model="tabs[index][LW_TAB_FIELDS.LW_LICENSE_KEY]"
            />
          </div>
        </div>
        <!--TITLE-->
        <div class="field">
          <label class="label has-text-primary"> {{ $t("admin_panel.lw_tab_title") }} </label>
          <b-button type="is-primary" class="admin-translation-key-button" @click="editTitle(index)">
            {{ tab[LW_TAB_FIELDS.TAB_TITLE_KEY] || $t("admin_panel.select_translation_key") }}
          </b-button>
        </div>
        <div class="close-icon" @click="removeTab">
          <icon-close class="svg-icon"></icon-close>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import LpConfigConstants from "@/shared/constants/lp-config-constants";
import IconClose from "@/assets/icon_close.svg";

export default {
  name: "LiveWebinarTabsBuilder",
  components: { IconClose },

  props: {
    value: {
      type: Array,
      required: true,
    },

    tabsEnabled: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      tabs: this.value,
      tabIndexAwaitingForTranslation: null,
    };
  },

  created() {
    this.$root.$on("translation-key-selected", this.onTranslationKeySelected);
  },

  beforeDestroy() {
    this.$root.$off("translation-key-selected", this.onTranslationKeySelected);
  },

  computed: {
    LW_TAB_FIELDS: () => LpConfigConstants.LIVEWEBINAR_TAB_FIELDS,
  },

  methods: {
    addTab() {
      this.tabs.push({
        [this.LW_TAB_FIELDS.TAB_TITLE_KEY]: null,
        [this.LW_TAB_FIELDS.LW_LICENSE_KEY]: null,
      });
    },

    removeTab(index) {
      this.tabs.splice(index, 1);
    },

    editTitle(index) {
      this.tabIndexAwaitingForTranslation = index;
      this.$root.$emit("select-translation-key");
    },

    onTranslationKeySelected(translationKey) {
      if (this.tabIndexAwaitingForTranslation !== null) {
        this.tabs[this.tabIndexAwaitingForTranslation][this.LW_TAB_FIELDS.TAB_TITLE_KEY] = translationKey;
      }
      this.tabIndexAwaitingForTranslation = null;
    },
  },
};
</script>

<style scoped lang="scss">
.close-icon {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 24px;
  height: 24px;
  cursor: pointer;
  border-radius: 2px;
  padding: 2px;

  &:hover {
    background: lightgrey;
  }
}
</style>
