<template>
  <div>
    <!--MODULE SPECIFIC FIELDS-->
    <div class="mt-1">
      <!--FACEBOOK URL-->
      <div class="field">
        <label class="label has-text-primary">{{ $t("admin_panel.social_links_facebook_url") }}</label>
        <div class="control">
          <input
            class="input is-simple"
            type="text"
            :placeholder="$t('admin_panel.social_links_facebook_url')"
            v-model="$data[socialLinkTypes.FACEBOOK]"
          />
        </div>
      </div>

      <!--YOUTUBE URL-->
      <div class="field">
        <label class="label has-text-primary">{{ $t("admin_panel.social_links_youtube_url") }}</label>
        <div class="control">
          <input
            class="input is-simple"
            type="text"
            :placeholder="$t('admin_panel.social_links_youtube_url')"
            v-model="$data[socialLinkTypes.YOUTUBE]"
          />
        </div>
      </div>

      <!--TWITTER URL-->
      <div class="field">
        <label class="label has-text-primary">{{ $t("admin_panel.social_links_twitter_url") }}</label>
        <div class="control">
          <input
            class="input is-simple"
            type="text"
            :placeholder="$t('admin_panel.social_links_twitter_url')"
            v-model="$data[socialLinkTypes.TWITTER]"
          />
        </div>
      </div>

      <!--LINKEDIN URL-->
      <div class="field">
        <label class="label has-text-primary">{{ $t("admin_panel.social_links_linked_in_url") }}</label>
        <div class="control">
          <input
            class="input is-simple"
            type="text"
            :placeholder="$t('admin_panel.social_links_linked_in_url')"
            v-model="$data[socialLinkTypes.LINKEDIN]"
          />
        </div>
      </div>

      <!--INSTAGRAM URL-->
      <div class="field">
        <label class="label has-text-primary">{{ $t("admin_panel.social_links_instagram_url") }}</label>
        <div class="control">
          <input
            class="input is-simple"
            type="text"
            :placeholder="$t('admin_panel.social_links_instagram_url')"
            v-model="$data[socialLinkTypes.INSTAGRAM]"
          />
        </div>
      </div>

      <!--WWW URL-->
      <div class="field">
        <label class="label has-text-primary">{{ $t("admin_panel.social_links_website_url") }}</label>
        <div class="control">
          <input
            class="input is-simple"
            type="text"
            :placeholder="$t('admin_panel.social_links_website_url')"
            v-model="$data[socialLinkTypes.WWW]"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LpConfigConstants from "@/shared/constants/lp-config-constants";

export default {
  name: "AdminModuleConfigSocialLinks",

  props: {
    value: {
      type: Object,
      required: true,
    },
  },

  data() {
    const socialLinkTypes = LpConfigConstants.SOCIAL_LINK_TYPES;
    const socialLinks = this.value[LpConfigConstants.SOCIAL_LINKS_MODULE_FIELDS.SOCIAL_LINKS];
    return {
      [socialLinkTypes.FACEBOOK]: getTargetValueFromSocialLinksArray(socialLinks, socialLinkTypes.FACEBOOK),
      [socialLinkTypes.YOUTUBE]: getTargetValueFromSocialLinksArray(socialLinks, socialLinkTypes.YOUTUBE),
      [socialLinkTypes.TWITTER]: getTargetValueFromSocialLinksArray(socialLinks, socialLinkTypes.TWITTER),
      [socialLinkTypes.LINKEDIN]: getTargetValueFromSocialLinksArray(socialLinks, socialLinkTypes.LINKEDIN),
      [socialLinkTypes.INSTAGRAM]: getTargetValueFromSocialLinksArray(socialLinks, socialLinkTypes.INSTAGRAM),
      [socialLinkTypes.WWW]: getTargetValueFromSocialLinksArray(socialLinks, socialLinkTypes.WWW),
    };
  },

  computed: {
    moduleFields: () => LpConfigConstants.SOCIAL_LINKS_MODULE_FIELDS,

    socialLinkTypes: () => LpConfigConstants.SOCIAL_LINK_TYPES,

    specificFieldsObject() {
      return {
        [this.moduleFields.SOCIAL_LINKS]: Object.values(this.socialLinkTypes)
          .reduce((acc, fieldKey) => {
            return [
              ...acc,
              {
                [this.moduleFields.SOCIAL_LINK_TYPE]: fieldKey,
                [this.moduleFields.SOCIAL_LINK_TARGET]: this.$data[fieldKey],
              },
            ];
          }, [])
          .filter(socialLink => socialLink[this.moduleFields.SOCIAL_LINK_TARGET]),
      };
    },
  },

  watch: {
    specificFieldsObject: {
      immediate: false,
      handler(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
};

function getTargetValueFromSocialLinksArray(array, socialLinkType) {
  const moduleFields = LpConfigConstants.SOCIAL_LINKS_MODULE_FIELDS;
  const socialLink = array && array.find(socialLink => socialLink[moduleFields.SOCIAL_LINK_TYPE] === socialLinkType);
  return socialLink ? socialLink[moduleFields.SOCIAL_LINK_TARGET] : null;
}
</script>

<style scoped></style>
