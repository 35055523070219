<template>
  <div
    class="is-relative fw-thread-image"
    :ratio="ratio"
    :class="viewPortClass"
    @click="toggle"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseExit"
  >
    <b-image :src="imageUrl" :ratio="ratio" :responsive="true" @load="imageLoaded = true">
      <template #placeholder>
        <b-skeleton class="skeleton-placeholder" height="100%" :animated="true"></b-skeleton>
      </template>
    </b-image>
    <transition name="fade">
      <div v-if="isHovering" class="image-overlay">{{ $t("feedwall.feedwall_show_full") }}</div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "FeedWallImage",

  data() {
    return {
      open: false,
      isHovering: false,
      imageLoaded: false,
    };
  },

  props: {
    attachment: {
      type: Object,
      required: true,
    },
  },

  computed: {
    height() {
      return this.attachment.meta && this.attachment.meta.height;
    },

    width() {
      return this.attachment.meta && this.attachment.meta.width;
    },

    ratio() {
      if (this.open || !this.canBeOpened) {
        return `${this.width}by${this.height}`;
      } else {
        return `2by1`;
      }
    },

    canBeOpened() {
      return this.width / 2 < this.height;
    },

    viewPortClass() {
      return this.canBeOpened ? "can-hover" : "";
    },

    imageUrl() {
      //I had to split this url to remove all query params from aws
      const segments = this.attachment.file_thumbnail_750_url.split("?");
      return segments[0];
      // return this.thread.image && this.thread.image.file_thumbnail_750_url;
    },
  },

  methods: {
    toggle() {
      if (this.canBeOpened) {
        this.open = !this.open;
        if (this.open) {
          this.isHovering = false;
        }
      }
    },

    onMouseEnter() {
      if (this.canBeOpened && !this.open) {
        this.isHovering = true;
      }
    },

    onMouseExit() {
      this.isHovering = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.image-overlay {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 24px;
  line-height: 130%;
  color: white;
  background: rgba(0, 0, 0, 0.3);
}

.b-skeleton {
  height: 100%;
  position: absolute;
  top: 0;
}

.fade-leave-active,
.fade-enter-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
