<template>
  <div class="fw-thread-list-item" :class="isSmallClass">
    <div class="columns is-mobile is-gapless mb-0 is-vcentered">
      <div class="column is-narrow">
        <profile-picture
          :picture="avatar"
          :initials="userInitials"
          class="fw-thread-avatar"
          :class="isSmallClass"
          @click.native.stop="openUserProfile"
        ></profile-picture>
      </div>
      <div class="column">
        <div class="fw-thread-title-container" :class="isSmallClass">
          <div class="is-flex">
            <div class="fw-thread-title is-flex-shrink-1" :class="isSmallClass">
              {{ title }}
            </div>
            <pin-icon class="fw-thread-sticky-pin-icon ml-1" :class="isSmallClass" v-if="isSticky"></pin-icon>
          </div>
          <div v-if="isSticky" class="fw-thread-administrator-tag">
            {{ $t("common.tag_administrator") }}
          </div>
          <div class="fw-thread-subtitle" :class="isSmallClass" v-else>
            {{ userPosition }}
          </div>
        </div>
      </div>
      <div class="column is-narrow is-flex">
        <div class="fw-thread-date" :class="isSmallClass">
          {{ date }}
        </div>
      </div>
    </div>
    <div class="fw-thread-message" :class="isSmallClass">
      <read-more-text :text="message"></read-more-text>
    </div>
    <feed-wall-image v-if="imageAttachment" :attachment="imageAttachment"></feed-wall-image>
    <feed-wall-comment
      class="fw-thread-comment"
      :comment="lastComment"
      :is-small="isSmall"
      :is-list-item="true"
      :channel-id="channelId"
      v-if="lastComment && isListItem"
      @click.stop.native="openThread"
    ></feed-wall-comment>
    <div class="fw-thread-buttons-container" :class="isSmallClass">
      <heart-icon-filled
        class="fw-thread-like-button"
        v-if="isLikedByMe"
        :class="isSmallClass"
        @click.stop="onLikeButtonClick"
      ></heart-icon-filled>
      <heart-icon class="fw-thread-like-button" v-else :class="isSmallClass" @click.stop="onLikeButtonClick"></heart-icon>
      <div class="fw-thread-likes" :class="isSmallClass" @click.stop="onLikeButtonClick">{{ likesCounter }}</div>
      <comment-icon class="fw-thread-comment-button" :class="isSmallClass" @click.stop="openThread"></comment-icon>
      <div class="fw-thread-comments" :class="isSmallClass" @click.stop="openThread">{{ commentsCounter }}</div>
      <b-dropdown aria-role="menu" class="ml-auto" position="is-top-left" trap-focus v-if="isFromCurrentUser || isAdmin">
        <template #trigger>
          <b-button class="is-borderless fw-thread-dots-button" size="is-small">
            <div class="is-flex is-align-items-center is-justify-content-center">
              <dots-icon class="fw-thread-dots-icon"></dots-icon>
            </div>
          </b-button>
        </template>
        <b-dropdown-item aria-role="listitem" @click="removeThread">{{ $t("feedwall.feedwall_delete_post") }} </b-dropdown-item>
        <b-dropdown-item aria-role="listitem" @click="editThread" v-if="isFromCurrentUser"
          >{{ $t("feedwall.feedwall_edit_post") }}
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <div v-if="!isListItem">
      <feed-wall-comment
        v-for="comment in comments"
        :key="comment.id"
        :comment="comment"
        :is-small="isSmall"
        :channel-id="channelId"
      ></feed-wall-comment>
      <list-loading-indicator :active="isLoading"></list-loading-indicator>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { initials, positionAndCompany, fullName, composeElapsedTime } from "@/shared/utils";
import FeedWallComment from "@/web/components/feedwall/FeedWallComment";
import ProfilePicture from "@/shared/components/common/profile/ProfilePicture";
import HeartIcon from "@/assets/feedwall/icon_heart.svg";
import HeartIconFilled from "@/assets/feedwall/icon_heart_filled.svg";
import CommentIcon from "@/assets/feedwall/icon_comment.svg";
import DotsIcon from "@/assets/icons/3_dots.svg";
import PinIcon from "@/assets/feedwall/icon_pin.svg";
import FeedWallImage from "@/web/components/feedwall/FeedWallImage";
import ListLoadingIndicator from "@/shared/components/common/loading/ListLoadingIndicator";
import ReadMoreText from "@/web/components/shared/ReadMoreText";
import FeedWallCreateThreadModal from "@/web/components/feedwall/FeedWallCreateThreadModal";
import FeedWallDeleteConfirmationModal from "@/web/components/feedwall/FeedWallDeleteConfirmationModal";

export default {
  name: "FeedWallThread",

  components: {
    ListLoadingIndicator,
    FeedWallImage,
    FeedWallComment,
    ProfilePicture,
    HeartIcon,
    HeartIconFilled,
    CommentIcon,
    PinIcon,
    DotsIcon,
    ReadMoreText,
  },

  props: {
    thread: {
      type: Object,
      required: true,
    },

    isSmall: {
      type: Boolean,
      default: false,
    },

    isListItem: {
      type: Boolean,
    },

    stickyTitle: {
      type: String,
      required: false,
    },
  },

  computed: {
    ...mapState(["event"]),
    ...mapGetters("currentUser", {
      currentUserUuid: "uuid",
    }),
    ...mapGetters("feedWallThread", ["commentsFromThread", "getIsLoading"]),
    ...mapGetters("auth", ["isAdmin"]),
    ...mapGetters(["currentTime"]),

    threadId() {
      return this.thread.id;
    },

    channelId() {
      return this.thread.channel_id;
    },

    message() {
      return this.thread.message;
    },

    date() {
      if (this.currentTime) {
        return composeElapsedTime(this.thread.created_at, this.$i18n);
      } else {
        return "";
      }
    },

    avatar() {
      if (this.isSticky) {
        return this.event.logo;
      } else {
        return this.thread.user.picture;
      }
    },

    title() {
      if (this.isSticky) {
        if (this.stickyTitle) {
          return this.stickyTitle;
        } else {
          return this.event.title;
        }
      } else {
        return fullName(this.thread.user);
      }
    },

    userInitials() {
      return initials(this.thread.user);
    },

    userPosition() {
      return positionAndCompany(this.thread.user);
    },

    userUuid() {
      return this.thread.user.user_identity_token;
    },

    isFromCurrentUser() {
      return this.userUuid === this.currentUserUuid;
    },

    isSticky() {
      return this.thread.is_sticky;
    },

    likesCounter() {
      return this.thread.likes_counter;
    },

    isLikedByMe() {
      return this.thread.liked_by_me;
    },

    commentsCounter() {
      return this.thread.comments_counter;
    },

    lastComment() {
      return this.thread.feed_wall_comment;
    },

    comments() {
      return this.commentsFromThread(this.threadId);
    },

    imageAttachment() {
      return this.thread.image;
    },

    isSmallClass() {
      if (this.isSmall) {
        return "is-small";
      } else {
        return "";
      }
    },

    isLoading() {
      return this.getIsLoading(this.threadId);
    },
  },

  methods: {
    ...mapActions("feedWallChannel", ["deleteThread"]),
    ...mapActions("feedWallThread", ["likeThread", "unlikeThread"]),

    onLikeButtonClick() {
      if (this.isLikedByMe) {
        this.unlikeThread({ channelId: this.channelId, threadId: this.threadId });
      } else {
        this.likeThread({ channelId: this.channelId, threadId: this.threadId });
      }
    },

    openThread() {
      this.$emit("open-thread", this.thread.id);
    },

    openUserProfile() {
      if (!this.isSticky) {
        this.$root.openUserModal(this.userUuid);
      }
    },

    removeThread() {
      this.$buefy.modal.open({
        parent: this,
        component: FeedWallDeleteConfirmationModal,
        hasModalCard: true,
        canCancel: true,
        trapFocus: true,
        props: { channelId: this.channelId, thread: this.thread },
      });
    },

    editThread() {
      this.$buefy.modal.open({
        parent: this,
        component: FeedWallCreateThreadModal,
        hasModalCard: true,
        canCancel: true,
        trapFocus: true,
        props: { channelId: this.channelId, thread: this.thread },
      });
    },
  },
};
</script>

<style scoped></style>
