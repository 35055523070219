<template>
  <!--MODULE SPECIFIC FIELDS-->
  <div class="mt-1">
    <!--HTML-->
    <div class="field">
      <label class="label has-text-primary">{{ $t("admin_panel.iframe_native_html") }}</label>
      <b-button type="is-primary" class="admin-translation-key-button" @click="editHtml()">
        {{ $data[moduleFields.HTML] || $t("admin_panel.select_translation_key") }}
      </b-button>
    </div>

    <!--SCRIPT-->
    <div class="field">
      <label class="label has-text-primary">{{ $t("admin_panel.iframe_native_javascript") }}</label>
      <b-button type="is-primary" class="admin-translation-key-button" @click="editScript()">
        {{ $data[moduleFields.SCRIPT] || $t("admin_panel.select_translation_key") }}
      </b-button>
    </div>
  </div>
</template>

<script>
import LpConfigConstants from "@/shared/constants/lp-config-constants";

export default {
  name: "AdminModuleConfigIFrame",

  props: {
    value: {
      type: Object,
      required: true,
    },
  },

  data() {
    const moduleFields = LpConfigConstants.IFRAME_NATIVE_MODULE_FIELDS;
    return {
      [moduleFields.SCRIPT]: this.value[moduleFields.SCRIPT],
      [moduleFields.HTML]: this.value[moduleFields.HTML],

      awaitingFieldForTranslationKey: null,
    };
  },

  created() {
    this.$root.$on("translation-key-selected", this.onTranslationKeySelected);
  },

  beforeDestroy() {
    this.$root.$off("translation-key-selected", this.onTranslationKeySelected);
  },

  computed: {
    moduleFields: () => LpConfigConstants.IFRAME_NATIVE_MODULE_FIELDS,

    specificFieldsObject() {
      return Object.values(this.moduleFields).reduce((acc, fieldKey) => {
        return { ...acc, [fieldKey]: this.$data[fieldKey] };
      }, {});
    },
  },

  methods: {
    editHtml() {
      this.awaitingFieldForTranslationKey = this.moduleFields.HTML;
      this.$root.$emit("select-translation-key");
    },

    editScript() {
      this.awaitingFieldForTranslationKey = this.moduleFields.SCRIPT;
      this.$root.$emit("select-translation-key");
    },

    onTranslationKeySelected(translationKey) {
      if (this.awaitingFieldForTranslationKey) {
        this.$data[this.awaitingFieldForTranslationKey] = translationKey;
      }
      this.awaitingFieldForTranslationKey = null;
    },
  },

  watch: {
    specificFieldsObject: {
      immediate: false,
      handler(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
};
</script>

<style scoped></style>
