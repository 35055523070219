<template>
  <div class="box">
    <div class="is-flex is-flex-direction-row is-align-items-center is-justify-content-center mb-2">
      <div class="is-size-5 mr-1">{{ $t("admin_panel.payment_configuration_title") }}</div>
      <b-button type="is-primary" class="is-small" @click="save">{{ $t("admin_panel.save") }}</b-button>
    </div>

    <div v-for="gateway in supportedGateways" :key="gateway">
      <div class="columns is-mobile is-vcentered is-variable is-1">
        <div class="column is-narrow">
          <checkbox size="20px" :value="$data[paymentFields.GATEWAYS].includes(gateway)" @input="toggleGateway(gateway)"></checkbox>
        </div>
        <div class="column is-size-7">
          {{ gateway }}
        </div>
      </div>
    </div>

    <!--DISABLE ORGANIZATIONAL DATA PAYMENT STEP-->
    <b-field>
      <b-switch v-model="$data[paymentFields.DISABLE_ORGANIZATIONAL_DATA_STEP]">{{ $t("admin_panel.payment_disable_organizational_data_step") }}</b-switch>
    </b-field>

    <!-- TICKET TERMS URL -->
    <div class="field">
      <label class="label has-text-primary">{{ $t("admin_panel.payment_terms_url") }}</label>
      <b-button
        type="is-primary"
        class="admin-translation-key-button"
        @click="editTranslationKey(paymentFields.TICKET_TERMS_URL)"
      >
        {{ $data[paymentFields.TICKET_TERMS_URL] || $t("admin_panel.select_translation_key") }}
      </b-button>
    </div>

    <!-- FOOTER BUTTON TEXT -->
    <div class="field">
      <label class="label has-text-primary">{{ $t("admin_panel.payment_footer_text") }}</label>
      <b-button
        type="is-primary"
        class="admin-translation-key-button"
        @click="editTranslationKey(paymentFields.FOOTER_BUTTON_TEXT_KEY)"
      >
        {{ $data[paymentFields.FOOTER_BUTTON_TEXT_KEY] || $t("admin_panel.select_translation_key") }}
      </b-button>
    </div>

    <!-- CHECKBOX TEXT -->
    <div class="field">
      <label class="label has-text-primary">{{ $t("admin_panel.payment_checkbox_text") }}</label>
      <b-button
        type="is-primary"
        class="admin-translation-key-button"
        @click="editTranslationKey(paymentFields.CHECKBOX_TEXT_KEY)"
      >
        {{ $data[paymentFields.CHECKBOX_TEXT_KEY] || $t("admin_panel.select_translation_key") }}
      </b-button>
    </div>

    <!-- CHECKBOX SUBSTRING -->
    <div class="field">
      <label class="label has-text-primary">{{ $t("admin_panel.payment_checkbox_substring") }}</label>
      <b-button
        type="is-primary"
        class="admin-translation-key-button"
        @click="editTranslationKey(paymentFields.CHECKBOX_SUBSTRING_KEY)"
      >
        {{ $data[paymentFields.CHECKBOX_SUBSTRING_KEY] || $t("admin_panel.select_translation_key") }}
      </b-button>
    </div>

    <!-- CALl TO ACTION CONFIG -->
    <div class="title has-text-centered">
      {{$t("admin_panel.payment_call_to_action_section_title")}}
    </div>
    <div class="subtitle has-text-centered">
      {{$t("admin_panel.payment_call_to_action_section_description")}}
    </div>

    <!-- CALL TO ACTION IMAGE URL -->
    <div class="field">
      <label class="label has-text-primary">{{ $t("admin_panel.payment_call_to_action_image_url") }}</label>
      <b-button
        type="is-primary"
        class="admin-translation-key-button"
        @click="editTranslationKey(paymentFields.CALL_TO_ACTION_IMAGE_URL_KEY)"
      >
        {{ $data[paymentFields.CALL_TO_ACTION_IMAGE_URL_KEY] || $t("admin_panel.select_translation_key") }}
      </b-button>
    </div>

    <!--CALL TO ACTION IMAGE HEIGHT-->
    <div class="field mt-1">
      <label class="label has-text-primary">{{ $t("admin_panel.payment_call_to_action_image_height") }}</label>
      <div class="control">
        <input class="input is-simple" type="number" step="1" min="0" v-model="$data[paymentFields.CALL_TO_ACTION_HEIGHT]" />
      </div>
    </div>

    <!--CALL TO ACTION IMAGE WIDTH-->
    <div class="field mt-1">
      <label class="label has-text-primary">{{ $t("admin_panel.payment_call_to_action_image_width") }}</label>
      <div class="control">
        <input class="input is-simple" type="number" step="1" min="0" v-model="$data[paymentFields.CALL_TO_ACTION_WIDTH]" />
      </div>
    </div>

    <!-- CALL TO ACTION TITLE-->
    <div class="field">
      <label class="label has-text-primary">{{ $t("admin_panel.payment_call_to_action_title") }}</label>
      <b-button
        type="is-primary"
        class="admin-translation-key-button"
        @click="editTranslationKey(paymentFields.CALL_TO_ACTION_TITLE_KEY)"
      >
        {{ $data[paymentFields.CALL_TO_ACTION_TITLE_KEY] || $t("admin_panel.select_translation_key") }}
      </b-button>
    </div>

    <!-- CALL TO ACTION TITLE COLOR-->
    <div class="field">
      <label class="label has-text-primary">{{ $t("admin_panel.payment_call_to_action_title_color") }}</label>
      <div class="control">
        <input
          class="input is-simple"
          type="color"
          v-model="$data[paymentFields.CALL_TO_ACTION_TITLE_TEXT_COLOR]"
        />
      </div>
      <template>
        <p v-if="!$v[paymentFields.CALL_TO_ACTION_TITLE_TEXT_COLOR].hexColor" class="help is-danger">
          {{ $t("admin_panel.error_hex_color") }}
        </p>
      </template>
    </div>

    <!-- CALL TO ACTION DESCRIPTION -->
    <div class="field">
      <label class="label has-text-primary">{{ $t("admin_panel.payment_call_to_action_description") }}</label>
      <b-button
        type="is-primary"
        class="admin-translation-key-button"
        @click="editTranslationKey(paymentFields.CALL_TO_ACTION_DESCRIPTION_KEY)"
      >
        {{ $data[paymentFields.CALL_TO_ACTION_DESCRIPTION_KEY] || $t("admin_panel.select_translation_key") }}
      </b-button>
    </div>

    <!-- CALL TO ACTION DESCRIPTION COLOR -->
    <div class="field">
      <label class="label has-text-primary">{{ $t("admin_panel.payment_call_to_action_description_color") }}</label>
      <div class="control">
        <input
          class="input is-simple"
          type="color"
          v-model="$data[paymentFields.CALL_TO_ACTION_DESCRIPTION_TEXT_COLOR]"
        />
      </div>
      <template>
        <p v-if="!$v[paymentFields.CALL_TO_ACTION_DESCRIPTION_TEXT_COLOR].hexColor" class="help is-danger">
          {{ $t("admin_panel.error_hex_color") }}
        </p>
      </template>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import LpConfigConstants from "@/shared/constants/lp-config-constants";
import Checkbox from "@/shared/components/form/Checkbox";
import LpConfigMixin from "@/shared/mixins/LpConfigMixin";
import {hexColor} from "@/shared/utils/form-validations";
import PaymentConstants from "@/shared/constants/payment-constants";

export default {
  name: "AdminPaymentConfiguration",
  mixins: [LpConfigMixin],
  components: { Checkbox },

  data() {
    const paymentFields = LpConfigConstants.PAYMENT_FIELDS;
    let data = Object.values(paymentFields).reduce((acc, fieldKey) => {
      return { ...acc, [fieldKey]: null };
    }, {});
    return {
      ...data,
      awaitingFieldForTranslationKey: null,
    };
  },

  validations() {
    const paymentFields = LpConfigConstants.PAYMENT_FIELDS;

    return {
      [paymentFields.CALL_TO_ACTION_TITLE_TEXT_COLOR]: { hexColor },
      [paymentFields.CALL_TO_ACTION_DESCRIPTION_TEXT_COLOR]: { hexColor },
    };
  },

  created() {
    this.$root.$on("translation-key-selected", this.onTranslationKeySelected);
  },

  beforeDestroy() {
    this.$root.$off("translation-key-selected", this.onTranslationKeySelected);
  },

  computed: {
    ...mapState("adminPanel", ["payment"]),

    paymentFields: () => LpConfigConstants.PAYMENT_FIELDS,

    supportedGateways() {
      return [PaymentConstants.GATEWAY_PAYU_ID, PaymentConstants.GATEWAY_STRIPE_ID, PaymentConstants.GATEWAY_BLUE_MEDIA_ID];
    },
  },

  methods: {
    ...mapMutations("adminPanel", ["setPaymentConfig"]),

    save() {
      this.setPaymentConfig(this.getPaymentConfig());
      this.$root.$emit("close-side-panel");
      this.$emit("navigate-back");
    },

    getPaymentConfig() {
      return this.getSpecificFieldsFromData();
    },

    getSpecificFieldsFromData() {
      return Object.values(this.paymentFields).reduce((acc, fieldKey) => {
        return { ...acc, [fieldKey]: this.$data[fieldKey] };
      }, {});
    },

    toggleGateway(gateway) {
      if (this.$data[this.paymentFields.GATEWAYS].includes(gateway)) {
        this.$data[this.paymentFields.GATEWAYS].splice(this.$data[this.paymentFields.GATEWAYS].indexOf(gateway), 1);
      } else {
        this.$data[this.paymentFields.GATEWAYS].push(gateway);
      }
    },

    editTranslationKey(translationKey) {
      this.awaitingFieldForTranslationKey = translationKey;
      this.$root.$emit("select-translation-key");
    },

    onTranslationKeySelected(translationKey) {
      if(this.awaitingFieldForTranslationKey) {
        this.$data[this.awaitingFieldForTranslationKey] = translationKey;
      }
      this.awaitingFieldForTranslationKey = null;
    },
  },

  watch: {
    payment: {
      immediate: true,
      handler: function (newValue) {
        if(newValue) {
          const paymentFields = LpConfigConstants.PAYMENT_FIELDS;

          this.$data[paymentFields.GATEWAYS] = [...this.getFieldValueOrDefault(newValue, paymentFields.GATEWAYS,[])];
          this.$data[paymentFields.DISABLE_ORGANIZATIONAL_DATA_STEP] = this.getFieldValueOrDefault(
            newValue,
            paymentFields.DISABLE_ORGANIZATIONAL_DATA_STEP,
            true
          );
          this.$data[paymentFields.TICKET_TERMS_URL] = this.getFieldValueOrDefault(newValue, paymentFields.TICKET_TERMS_URL, null);
          this.$data[paymentFields.FOOTER_BUTTON_TEXT_KEY] = this.getFieldValueOrDefault(
            newValue,
            paymentFields.FOOTER_BUTTON_TEXT_KEY,
            null
          );
          this.$data[paymentFields.CHECKBOX_TEXT_KEY] = this.getFieldValueOrDefault(newValue, paymentFields.CHECKBOX_TEXT_KEY, null);
          this.$data[paymentFields.CHECKBOX_SUBSTRING_KEY] = this.getFieldValueOrDefault(
            newValue,
            paymentFields.CHECKBOX_SUBSTRING_KEY,
            null
          );
          this.$data[paymentFields.CALL_TO_ACTION_IMAGE_URL_KEY] = this.getFieldValueOrDefault(newValue, paymentFields.CALL_TO_ACTION_IMAGE_URL_KEY, null);
          this.$data[paymentFields.CALL_TO_ACTION_TITLE_KEY] = this.getFieldValueOrDefault(newValue, paymentFields.CALL_TO_ACTION_TITLE_KEY, null);
          this.$data[paymentFields.CALL_TO_ACTION_DESCRIPTION_KEY] = this.getFieldValueOrDefault(newValue, paymentFields.CALL_TO_ACTION_DESCRIPTION_KEY, null);
          this.$data[paymentFields.CALL_TO_ACTION_HEIGHT] = this.getFieldValueOrDefault(newValue, paymentFields.CALL_TO_ACTION_HEIGHT, null);
          this.$data[paymentFields.CALL_TO_ACTION_WIDTH] = this.getFieldValueOrDefault(newValue, paymentFields.CALL_TO_ACTION_WIDTH, null);
          this.$data[paymentFields.CALL_TO_ACTION_TITLE_TEXT_COLOR] = this.getFieldValueOrDefault(newValue, paymentFields.CALL_TO_ACTION_TITLE_TEXT_COLOR, null);
          this.$data[paymentFields.CALL_TO_ACTION_DESCRIPTION_TEXT_COLOR] = this.getFieldValueOrDefault(newValue, paymentFields.CALL_TO_ACTION_DESCRIPTION_TEXT_COLOR, null);

        }
      },
    },
  },
};
</script>

<style scoped></style>
