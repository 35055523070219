<template>
  <div class="box">
    <b-button type="is-primary" class="has-fullwidth mb-1" @click="addTab" v-if="tabsEnabled || tabs.length === 0">
      {{ $t("admin_panel.add_tab") }}
    </b-button>

    <template v-for="(tab, index) in tabs">
      <div class="field box has-image is-relative" v-if="index === 0 || tabsEnabled" :key="`tab-${index}`">
        <!--TITLE-->
        <div class="field">
          <label class="label has-text-primary">{{ $t("admin_panel.iframe_tab_builder_tab_title") }}</label>
          <b-button type="is-primary" class="admin-translation-key-button" @click="editTitle(index)">
            {{ tab[IFRAME_TAB_FIELDS.TITLE] || $t("admin_panel.select_translation_key") }}
          </b-button>
        </div>

        <!--HTML-->
        <div class="field">
          <label class="label has-text-primary">{{ $t("admin_panel.iframe_tab_builder_html") }}</label>
          <b-button type="is-primary" class="admin-translation-key-button" @click="editHtml(index)">
            {{ tab[IFRAME_TAB_FIELDS.HTML] || $t("admin_panel.select_translation_key") }}
          </b-button>
        </div>

        <!--SCRIPT-->
        <div class="field">
          <label class="label has-text-primary">{{ $t("admin_panel.iframe_tab_builder_java_script") }}</label>
          <b-button type="is-primary" class="admin-translation-key-button" @click="editScript(index)">
            {{ tab[IFRAME_TAB_FIELDS.SCRIPT] || $t("admin_panel.select_translation_key") }}
          </b-button>
        </div>

        <div class="close-icon" @click="removeTab">
          <icon-close class="svg-icon"></icon-close>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import LpConfigConstants from "@/shared/constants/lp-config-constants";
import IconClose from "@/assets/icon_close.svg";

export default {
  name: "IFrameTabsBuilder",
  components: { IconClose },

  props: {
    value: {
      type: Array,
      required: true,
    },

    tabsEnabled: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      tabs: this.value,
      tabIndexAwaitingForTranslation: null,
      awaitingFieldForTranslationKey: null,
    };
  },

  created() {
    this.$root.$on("translation-key-selected", this.onTranslationKeySelected);
  },

  beforeDestroy() {
    this.$root.$off("translation-key-selected", this.onTranslationKeySelected);
  },

  computed: {
    IFRAME_TAB_FIELDS: () => LpConfigConstants.IFRAME_TAB_FIELDS,
  },

  methods: {
    addTab() {
      this.tabs.push({
        [this.IFRAME_TAB_FIELDS.TITLE]: null,
        [this.IFRAME_TAB_FIELDS.SCRIPT]: null,
        [this.IFRAME_TAB_FIELDS.HTML]: null,
      });
    },

    removeTab(index) {
      this.tabs.splice(index, 1);
    },

    editTitle(index) {
      this.tabIndexAwaitingForTranslation = index;
      this.awaitingFieldForTranslationKey = this.IFRAME_TAB_FIELDS.TITLE;
      this.$root.$emit("select-translation-key");
    },

    editHtml(index) {
      this.tabIndexAwaitingForTranslation = index;
      this.awaitingFieldForTranslationKey = this.IFRAME_TAB_FIELDS.HTML;
      this.$root.$emit("select-translation-key");
    },

    editScript(index) {
      this.tabIndexAwaitingForTranslation = index;
      this.awaitingFieldForTranslationKey = this.IFRAME_TAB_FIELDS.SCRIPT;
      this.$root.$emit("select-translation-key");
    },

    onTranslationKeySelected(translationKey) {
      if (this.tabIndexAwaitingForTranslation !== null && this.awaitingFieldForTranslationKey !== null) {
        this.tabs[this.tabIndexAwaitingForTranslation][this.awaitingFieldForTranslationKey] = translationKey;
      }
      this.tabIndexAwaitingForTranslation = null;
      this.awaitingFieldForTranslationKey = null;
    },
  },
};
</script>

<style scoped lang="scss">
.close-icon {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 24px;
  height: 24px;
  cursor: pointer;
  border-radius: 2px;
  padding: 2px;

  &:hover {
    background: lightgrey;
  }
}
</style>
